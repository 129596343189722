import WorkspaceData from '../WorkspaceData';
import router from "@/router";

export default class HochzeitsrauschWorkspace extends WorkspaceData {
  constructor() {
    super();
    this.customerId = 'hochzeitsrausch';
    console.log('Custom workspace loaded: hochzeitsrausch');
  }

  getRenderAllowance() {
    return (typeof this.workspace.dynamic_signals !== 'undefined') && (this.workspace.dynamic_signals != null);
  }

  getRenderQuery() {
    if(this.getRenderAllowance()) {
      return `seed=${this.workspace.seed}&customerCode=${this.customerId}`;
    }
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text:`missing dynamic signals`,
      },
    });

    return false;
  }

  setDynamicSignals(dynamicSignals) {
    if (dynamicSignals) {
      this.workspace.dynamic_signals = dynamicSignals;
      this.renderTemplate();
    }
  }

  async signalSearch(signalArray) {
    if(signalArray.length > 0) {
      let selectors = {
        'selectors': signalArray
      }

      const spinner = document.getElementById("spinner");
      spinner.removeAttribute('hidden');

      let response = await fetch('/api/v1/templates/signals?customerCode=hochzeitsrausch',
          {
            method: 'POST',
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectors)
          });

      let result = await response.json();
      spinner.setAttribute('hidden', '');

      switch (response.status) {
        case 200:
          this.setDynamicSignals(result.signals);
          this.dispatchEvent( {
            type: 'toast-msg',
            message: {
              type: 'info',
              text: `Signale wurden neu geladen.`,
            },
          });
          break;
        case 401:
          await router.push({name:'Login'});
          break;
        default:
          this.dispatchEvent( {
            type: 'toast-msg',
            message: {
              type: 'error',
              text: `(${response.status}): ${response.statusText}`,
            },
          });
      }
    }
  }
}